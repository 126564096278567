@import "~bootstrap/scss/bootstrap.scss";

.shoppingBag {
  @extend .nav-item;
  @extend .nav-link;
  @extend .btn;
}

.cartPreviewContainer {
  position: absolute;
  z-index: 100;
  right: 10px;
  top: 70px;
}
