$grid-gutter-width: 10px;

@import "~bootstrap/scss/bootstrap.scss";

.productContainer {
  @extend .clearfix;
  @extend .row;
}

.imageContainer {
  @extend .col-3;
  @extend .align-self-center;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.descriptionContainer {
  @extend .col-6;
}

.priceContainer {
  @extend .col-3;
  text-align: right;
  font-weight: bold;
}

.name {
  @extend .text-capitalize, .font-weight-bold;
}

.attributeList {
  @extend .row;
  margin-bottom: 0px;
}

$attributeFontSize: smaller;

.attributeTerm {
  @extend .col-sm-3;
  color: gray;
  font-weight: lighter;
  font-size: $attributeFontSize;
}

.attributeDefinition {
  @extend .col-sm-9;
  margin-bottom: 0px;
  font-size: $attributeFontSize;
}

.seperator {
  margin-top: 0px;
}

.buttonContainer {
  @extend .col-sm
}
